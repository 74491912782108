<div class="peacock">
    <img src="../../../../../assets/img/PeacockLogo_RGB.png">
    <h1>LIVE EVENT MANAGER</h1>
</div>
<h2 mat-dialog-title (click)="debugService.toggleDebug($event)">About</h2>
<div mat-dialog-content class="mat-typography">
  <div class="about-line" *ngFor="let item of about">
    <div class="about-label">{{item.label}}</div>
    <div class="about-value">{{item.value}}</div>
  </div>
</div>
<div mat-dialog-actions class="exit-about">
  <button mat-raised-button mat-dialog-close >
    <mat-icon>exit_to_app</mat-icon>
    Close
  </button>
</div>
