import { Injectable } from '@angular/core';
import {Info} from "../../../../typings/db";
import {Observable} from 'rxjs/Observable';
import {Apollo, QueryRef} from "apollo-angular";
import {envAllQuery} from '../../../graphql/db/env/envAll.query';
import {ApolloQueryResult} from '@apollo/client/core';



@Injectable({
    providedIn: 'root'
})
export class InformationService {
    private envAllQuery: QueryRef<Info.Query>;

  constructor(private apollo: Apollo) {
      this.envAllQuery = this.apollo.watchQuery<Info.Query>({query: envAllQuery});
  }

     public getBackendVersions():  Observable<ApolloQueryResult<Info.Query>>{
         return this.envAllQuery.valueChanges;
     }

}
