<div class="main-page">
    <div class="main-header">
        <a class="compagny-logo" [routerLink]="['/event-list']" routerLinkActive="active">
            <img src="/assets/img/PeacockLogo_RGB.png">
        </a>

        <div class="middle-header" (click)="debugService.toggleBroadcastDebug($event)">
            <div *ngIf="debugService.broadcastDebug" class="debug">
                <span>DEBUG MODE:</span>
                <mat-radio-group
                    aria-labelledby="debug-mode-selector"
                    class="debug-mode-group"
                    [(ngModel)]="debugService.selectedDebugMode">
                    <mat-radio-button
                        *ngFor="let mode of debugService.debugModes"
                        class="debug-mode-button"
                        [value]="mode">
                        {{mode}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="user" (click)="logOut()">
            <i class="material-icons user-icon">person</i>
        </div>
    </div>

    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <div class="main-footer">
        <div class="info">
            System Application <span class="about-link" (click)="openAbout()">Information</span>
        </div>
        <div class="copyright">
            Licensed by <a class="about-link" href="http://harmonicinc.com">Harmonic/NBC</a>
        </div>
    </div>
</div>
