import {Routes} from "@angular/router";
import {OktaCallbackComponent, OktaLoginRedirectComponent} from "../okta-angular/src";
import {CustomLogoutComponent} from "../custom-logout/custom-logout/custom-logout.component";

export enum RoutesOktaEnum {
  login = 'login',
  authCallback = 'auth/callback',
  noPermission = 'no-permission',
  customLogin = 'customLogin',
  customLogout = 'customLogout'
}

/*export const OktaRoutes: Routes = [
  {
    path: RoutesOktaEnum.login,
    component: OktaLoginRedirectComponent,
  },
  {
    path: RoutesOktaEnum.customLogin,
    loadChildren: () => import('../custom-login/custom-login.module').then(m => m.CustomLoginModule)
    // loadChildren: './custom-login/custom-login.module#CustomLoginModule'
  },
  {
    path: RoutesOktaEnum.customLogout,
    component: CustomLogoutComponent
  },
  {
    path: RoutesOktaEnum.authCallback,
    component: OktaCallbackComponent
  },
  {
    path: RoutesOktaEnum.noPermission,
    loadChildren: () => import('../no-permission+/no-permission.module').then(m => m.NoPermissionModule)
    // loadChildren: './no-permission+/no-permission.module#NoPermissionModule'
  },
];*/
