import {Injectable} from '@angular/core';

export enum DebugMode {
    'VOS_1' = 'VOS #1',
    'VOS_2' = 'VOS #2',
    'BOTH' = 'BOTH'
}

@Injectable({
    providedIn: 'root'
})
export class DebugService {

    debug: boolean = false;
    broadcastDebug: boolean = false;
    debugModes: Array<DebugMode> = [DebugMode.VOS_1, DebugMode.VOS_2, DebugMode.BOTH];
    selectedDebugMode: DebugMode = this.debugModes[2];

    constructor() {
    }

    toggleDebug($event) {
        if ($event.shiftKey && $event.ctrlKey) {
            // toggle debug mode
            this.debug = !this.debug;
        }
    }

    toggleBroadcastDebug($event) {
        if ($event.shiftKey && $event.ctrlKey) {
            // toggle broadcast debug mode
            this.broadcastDebug = !this.broadcastDebug;
        }
    }

    toggleBroadcastDebug2() {
        // toggle broadcast debug mode
        this.broadcastDebug = !this.broadcastDebug;
    }

}
