import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Injectable} from "@angular/core";

import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {OktaAuthService} from "../../okta-angular/src";

@Injectable()
export class UnauthorizedService implements HttpInterceptor {
    private static reload (): void {
        window.location.reload(true);
    }

    constructor(private oktaAuthService: OktaAuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(req)
            .pipe(
                tap(() => {}, (err) => {
                    if (err.status === 401) {
                        this.oktaAuthService
                            .logout('/')
                            .then(() => { UnauthorizedService.reload() })
                            .catch(() => { UnauthorizedService.reload() });
                    }
                }))
    }
}
