
import {Inject, NgModule} from "@angular/core";
import { CommonModule, DOCUMENT } from "@angular/common";
import {OktaAuthService} from "../../okta-angular/src";
import {RoutesOktaEnum} from "../../routes/okta-route.model";


@NgModule({
    imports: [CommonModule]
})

export class LocationInit {
    constructor(@Inject(DOCUMENT) document: any, private okta: OktaAuthService) {
        const url = document.location.origin;

        // modify Okta auth service options with current location
        // @ts-ignore
        okta.oktaAuth.options.redirectUri = url + '/' + RoutesOktaEnum.authCallback;
    }
}


