import { gql } from 'apollo-angular';

export const envAllQuery = gql`
     query info {
        envAll {
            backendVersion
            backendVersionBuild
            backendVersionBuildtime
            databaseServerVersion
            databaseSchemaInfo
            backendVersionSystem
            networkUserGroupMapping
        }
     }
    `;
