import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {OktaAuthGuard, OktaCallbackComponent, OktaLoginRedirectComponent} from "./libs/okta-angular/src";
import {PermissionsGuard} from "./libs/guards/permissions.guard";
import {RoutesEnum} from "./route-enum";
import {RoutesOktaEnum} from "./libs/routes/okta-route.model";
import {CustomLogoutComponent} from "./libs/custom-logout/custom-logout/custom-logout.component";

const routes: Routes = [
  {
    path: RoutesOktaEnum.login,
    component: OktaLoginRedirectComponent,
  },
  {
    path: RoutesOktaEnum.customLogin,
    loadChildren: () => import('./libs/custom-login/custom-login.module').then(m => m.CustomLoginModule)
    // loadChildren: './custom-login/custom-login.module#CustomLoginModule'
  },
  {
    path: RoutesOktaEnum.customLogout,
    component: CustomLogoutComponent
  },
  {
    path: RoutesOktaEnum.authCallback,
    component: OktaCallbackComponent
  },
  {
    path: RoutesOktaEnum.noPermission,
    loadChildren: () => import('./libs/no-permission+/no-permission.module').then(m => m.NoPermissionModule)
    // loadChildren: './no-permission+/no-permission.module#NoPermissionModule'
  },
    {
      path: '',
      redirectTo: "/event-list",
      pathMatch: "full",
    },
    {
      path: RoutesEnum.eventList,
      canActivate: [OktaAuthGuard],
      // canActivateChild: [PermissionsGuard],
      loadChildren: () => import('./core/+event-list/event-list.module').then(m => m.EventListModule)
      // loadChildren: './core/+event-list/event-list.module#EventListModule'
    },
    {
      path: RoutesEnum.eventConf,
      canActivate: [OktaAuthGuard],
      // canActivateChild: [PermissionsGuard],
      loadChildren: () => import('./core/+event-conf/event-conf.module').then(m => m.EventConfModule)
      // loadChildren: './core/+event-conf/event-conf.module#EventConfModule'
    },
    {path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}


