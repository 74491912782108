
<div class="login-form-container">
  <div class="container panel panel-default panel-extended">
    <div class="signin-brand">

    </div>

    <div class="card mt-2">

      <div class="card-body">
        <form>
          <p id="custom-logout-title" class="form-signin-heading">You are logged out now...</p>
          <div class="form-group">
            <a class="btn btn-lg btn-primary btn-block login-btn" routerLink="/login"> Login again</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

