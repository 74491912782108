import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, from} from 'rxjs';
import {map, switchMap} from "rxjs/operators";
import {OktaAuthService} from "../../okta-angular/src";

@Injectable()
export class TokenService implements HttpInterceptor {
    constructor(private oktaAuthService: OktaAuthService) {
      console.log('TokenService - init');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.has('Authorization')) {
            return next.handle(request);
        }

        // TODO: UPGRADE IN PROGRESS
        /*
        ERROR in vos_scheduler_frontend/src/app/shared-OLD/auth/interceptions/token.service.ts(18,29): error TS2345: Argument of type 'Promise<string>' is not assignable to parameter of type 'string'.
            vos_scheduler_frontend/src/app/shared-OLD/auth/interceptions/token.service.ts(22,44): error TS2345: Argument of type 'unknown' is not assignable to parameter of type 'HttpRequest<any>'.
            Type '{}' is missing the following properties from type 'HttpRequest<any>': url, body, headers, reportProgress, and 8 more.
            */
        return from<any>(this.oktaAuthService.getAccessToken()).pipe(
            map((token: any) => request.clone({
                setHeaders: {Authorization: `Bearer ${token}`}
            })),
            switchMap((req) => next.handle(req)),
        );
    }
}

