import {Component, OnInit, VERSION} from '@angular/core';
import {DebugService} from "../../services/debug.service";
import {InformationService} from "../../services/information.service";
import {Info} from "../../../../../typings/db";
import {APP_VERSION} from "../../../../version";

@Component({
    selector: 'app-about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent implements OnInit {

    about: Array<{ label: string, value: string }>;
    private backendVersions: Info.EnvAll;
    private frontendVersion: string;
    private bundleVersion: string;

    constructor(public debugService: DebugService, public infoService: InformationService) {
    }

    ngOnInit(): void {
        this.frontendVersion = APP_VERSION;
        this.bundleVersion = BUNDLE_VERSION ? BUNDLE_VERSION : 'Unknown';

        this.infoService.getBackendVersions()
            .subscribe(response => {
                    this.backendVersions = response.data.envAll;
                    this.initView();
                },
                error => {
                    // tslint:disable-next-line:no-console
                    console.log('Backend connection failed: ', error);
                });

    }

    private initView(): void {
        this.about = [
            {label: 'Frontend version', value: this.frontendVersion},
            {label: 'Angular version', value: `${VERSION.full}`},
            {label: 'Backend version', value: this.backendVersions.backendVersion},
            {label: 'Backend version build', value: this.backendVersions.backendVersionBuild},
            {label: 'Backend version build time', value: this.backendVersions.backendVersionBuildtime},
            {label: 'Backend version system', value: this.backendVersions.backendVersionSystem},
            {label: 'Data base version', value: this.backendVersions.databaseServerVersion},
            {label: 'Data base info', value: this.backendVersions.databaseSchemaInfo},
            {label: 'Network user group mapping', value: this.backendVersions.networkUserGroupMapping},
            {label: 'Bundle version', value: this.bundleVersion}
        ];
    }
}
