import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import { onError } from '@apollo/client/link/error';

/// <reference path="../../typings/bundle.d.ts"/>

import {InMemoryCache} from '@apollo/client/core';
import {Apollo} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {ApolloLink} from '@apollo/client/core';
import {OktaAuthService} from "../app/libs/okta-angular/src";

//const multipartMutations = ['createOttChannel', 'createPlayoutChannel', 'updateOttChannel', 'updatePlayoutChannel', 'createChannelGroup',
//    'updateGenericChannelGroup', 'updateGlobalSettings'];

const NOT_AUTHENTICATED_ERR_MSG = 'Not Authenticated';
const HEADER_EXPIRED_ERR_MSG = 'Authorization header has been expired';


@NgModule({
    imports: [
    ],
    exports: [
        HttpClientModule,
    ],
})
export class GraphQLApolloModule {
    constructor(private apollo: Apollo, private httpLink: HttpLink, private oktaAuthService: OktaAuthService) {
        this.init();
    }

    private init() {
       const sched360Uri = GRAPHQL_SERVER_URL ? GRAPHQL_SERVER_URL : 'http://localhost:4000';
       const lemUri = '';
       const sched360Http : ApolloLink = this.httpLink.create({uri : sched360Uri});
        const lemHttp : ApolloLink = this.httpLink.create({uri : lemUri});

    /*    const header = setContext(async () => {
            const token = await this.oktaAuthService.getAccessToken();
            return {headers: {Authorization: token}};
        });
        const uploadLink = createUploadLink({uri});

        const introspectionQueryResultData = FragmentTypes.FRAGMENT_TYPES.data;
*/      //  const fragmentMatcher = new IntrospectionFragmentMatcher({introspectionQueryResultData});
        const cache = new InMemoryCache();

	    const errorLink = onError(({ graphQLErrors, networkError }) => {
		    if (graphQLErrors)
		        graphQLErrors.forEach(error => {
                    if(error.message === HEADER_EXPIRED_ERR_MSG || error.message === NOT_AUTHENTICATED_ERR_MSG) {
				        this.oktaAuthService.logout('/login');
			        }
                });

		    if (networkError) {
                 console.log(`[Network error]: ${networkError}`);
            }
	    });

        const networkLink = ApolloLink.split((operation) => operation.operationName.startsWith('_lem') , lemHttp, sched360Http);

        const links = ApolloLink.from([networkLink, errorLink]);
        this.apollo.create({cache, link: links});

     }
}
