import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CustomMaterialModule} from "./custom-material-module";
import {SharedModule} from "./shared/shared.module";
import {MatNativeDateModule} from "@angular/material/core";
import {AboutDialogComponent} from './core/common/components/about-dialog/about-dialog.component';
import {OktaAuthModule} from "./libs/okta-angular/src";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {TokenService} from "./libs/auth/interceptions/token.service";
import {UnauthorizedService} from "./libs/auth/interceptions/unauthorized.service";
import {LocationInit} from "./libs/auth/config/auth-config";
import {GraphQLApolloModule} from "../configs/apollo.config";

@NgModule({
    imports: [
        OktaAuthModule.initAuth(),
        FormsModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CustomMaterialModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        SharedModule,
        LocationInit,
        GraphQLApolloModule,
    ],
    declarations: [AppComponent, AboutDialogComponent],
    exports: [],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenService,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: UnauthorizedService,
        multi: true
      },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {
    }

}


/*
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';

import {OktaAuthModule} from '@okta/okta-angular';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ColorPickerModule} from 'ngx-color-picker';
import {LoadingModule} from 'ngx-loading';
import {DndModule} from 'ng2-dnd';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';


import {ROUTES} from './app.routes';
import {GraphQLModule} from './share-OLD/apollo.config';

import {NgSelectModule} from '@ng-select/ng-select';

import {NgxChartsModule} from '@swimlane/ngx-charts';

import {ContextService} from "./services/context.service";
import {UtilsService} from "./services/utils.service";
import {ChannelsService} from "./channels/channels.service";
import {GlobalSettingsService} from "./global-settings/global-settings.service";
import {DynamicViewService} from './dynamic-typed/dynamic-view.service';
import {SchedulesService} from "./channels/channel/schedules/schedules.service";
import {SevUtilsService} from "./secondaries/sev-utils.service";

import {AppComponent} from './app.component';
import {MainFooterComponent} from './page-template/footer/footer.component';
import {MainCoreComponent} from './page-template/core/core.component';
import {MainHeaderComponent} from './page-template/header/header.component';
import {DynamicTypeComponent} from './dynamic-typed/dynamic-type/dynamic-type.component';
import {DynamicPanelComponent} from './dynamic-typed/dynamic-panel/dynamic-panel.component';
import {StringComponent} from './dynamic-typed/string/string.component';
import {DurationComponent} from './dynamic-typed/duration/duration.component';
import {NumberComponent} from './dynamic-typed/number/number.component';
import {EnumComponent} from './dynamic-typed/enum/enum.component';
import {DateComponent} from './dynamic-typed/date/date.component';
import {ListComponent} from './dynamic-typed/list/list.component';
import {MainMenuComponent} from './page-template/header/main-menu/main-menu.component';
import {UserBadgeComponent} from './page-template/header/user-badge/user-badge.component';
import {HomeComponent} from './home/home.component';
import {ChannelDetailsComponent} from './channels/channel/channel-details/channel-details.component';
import {ChannelsConfigComponent} from './channels/channels-config/channels-config.component';
import {StatisticsComponent} from './channels/channel/statistics/statistics.component';
import {GlobalSettingsComponent} from './global-settings/global-settings.component';
import {ImageComponent} from "./dynamic-typed/image/image.component";
import {ColorComponent} from './dynamic-typed/color/color.component';
import {DialogComponent} from "./dialog/dialog.component";
import {TextComponent} from './dynamic-typed/text/text.component';
import {DateTimeComponent} from './dynamic-typed/date-time/date-time.component';
import {MainChannelsComponent} from "./dynamic-typed/enum/mainChannels/mainChannels.component";
import {GroupsComponent} from "./groups/groups.component";
import {GroupSettingsComponent} from './groups/group-settings/group-settings.component';
import {GroupDetailsComponent} from './groups/group-details/group-details.component';
import {ChannelsModalComponent} from './channels/channels-config/channels-modal/channels-modal.component';

import {UserInfoModalComponent} from './page-template/header/user-badge/user-info-modal/user-info-modal.component';
import {TokenService} from "./shared-OLD/auth/interceptions/token.service";
import {UnauthorizedService} from "./shared-OLD/auth/interceptions/unauthorized.service";
import {SearchBarComponent} from './search-bar/search-bar.component';
import {ScheduleDetailsComponent} from './channels/channel/schedules/schedule-details/schedule-details.component';
import {OttRightsDetailsComponent} from './ott/ott-rights-details/ott-rights-details.component';
import {BooleanComponent} from './dynamic-typed/boolean/boolean.component';
import {ProgramsListingComponent} from './programs-listing/programs-listing.component';
import {ProgramItemRowComponent} from './programs-listing/components/program-item-row/program-item-row.component';
import {CriteriaComponent} from './programs-listing/components/criteria/criteria.component';
import {SharedModule} from "./shared-OLD/shared-OLD.module";
import {SearchCriteriaService} from "./programs-listing/services/search-criteria.service";
import {TimeRemainingPipe} from './programs-listing/pipes/time-remaining.pipe';
import {UrgencyPipe} from './programs-listing/pipes/urgency.pipe';
import {httpLoaderFactory} from "../translation/httpLoaderFactory";
import {LocationInit} from "./shared-OLD/auth/config/auth-config";
import {ValidateAuthGuard} from "./shared-OLD/auth/guards/validate-auth.guard";
import {CriteriaLabelComponent} from './programs-listing/components/criteria-label/criteria-label.component';
import {UniqueCriteriaOptionPipe} from './programs-listing/pipes/unique-criteria-option.pipe';
import {OttRightsMultipleComponent} from './ott/ott-rights-multiple/ott-rights-multiple.component';
import {AddHarmonicIdComponent} from "./user-management/add-harmonic-id/add-harmonic-id.component";
import {UserAddComponent} from './user-management/add-harmonic-id/user-add/user-add.component';

import {DeleteModalComponent} from "./user-management/modify-user-permissions/delete-modal/delete-modal.component";
import {ModifyUserPermissionsComponent} from './user-management/modify-user-permissions/modify-user-permissions.component';
import {UserProfilHeaderComponent} from './user-management/user-profil-header/user-profil-header.component';

import {StatisticComponent} from './home/statistic/statistic.component';

import {GroupsStatisticComponent} from './home/statistic/groups-statistic/groups-statistic.component';
import {GroupSynthesisTileComponent} from './home/statistic/groups-statistic/group-synthesis-tile/group-synthesis-tile.component';
import {GroupTileComponent} from './home/statistic/groups-statistic/group-tile/group-tile.component';

import {ChannelsStatisticComponent} from './home/statistic/channels-statistic/channels-statistic.component';
import {ChannelSynthesisTileComponent} from './home/statistic/channels-statistic/channel-synthesis-tile/channel-synthesis-tile.component';
import {ChannelTileComponent} from './home/statistic/channels-statistic/channel-tile/channel-tile.component';
import {BasicTileComponent} from './home/statistic/basic-tile/basic-tile.component';
import {ChannelCreationModalComponent} from './channels/channel/channel-details/channel-creation-modal/channel-creation-modal.component';
import {ProgramSorterComponent} from './media-manager/components/media-details/program-sorter/program-sorter.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {UsersManagementComponent} from "./user-management/users-management.component";
import {PermissionsComponent} from "./user-management/permissions/permissions.component";
import {UsersListComponent} from "./user-management/users-list/users-list.component";
import {UserItemComponent} from "./user-management/users-list/user-item/user-item.component";
import {UserManagementModule} from "./user-management/user-management.module";
import {EditModalComponent} from './shared-OLD/components/edit-modal/edit-modal.component';
import {MultiSelectComponent} from './dynamic-typed/multi-select/multi-select.component';
import {ExportOpcModalComponent} from './channels/channel/schedules/export-opc-modal/export-opc-modal.component';
import {AdvancedSearchModalComponent} from './advanced-search-modal/advanced-search-modal.component';
import {SecondariesComponent} from './secondaries/secondaries.component';
import {SecondaryTreeComponent} from './secondaries/secondary-tree/secondary-tree.component';
import {SecondaryTreeNodeComponent} from './secondaries/secondary-tree/secondary-nodes/secondary-tree-node/secondary-tree-node.component';
import {GroupNodeComponent} from './secondaries/secondary-tree/secondary-nodes/group-node/group-node.component';
import {ChannelNodeComponent} from './secondaries/secondary-tree/secondary-nodes/channel-node/channel-node.component';
import {GraphicNodeComponent} from './secondaries/secondary-tree/secondary-nodes/graphic-node/graphic-node.component';
import {SecondaryDetailsComponent} from './secondaries/secondary-details/secondary-details.component';
import {SecondaryCreationModalComponent} from './secondaries/secondary-details/secondary-creation-modal/secondary-creation-modal.component';

import {DailyPrimaryContainerComponent} from './channels/channel/schedules/primary/daily/daily-primary-container/daily-primary-container.component';
import {WeeklyPrimaryContainersComponent} from './channels/channel/schedules/primary/weekly/weekly-primary-containers/weekly-primary-containers.component';
import {WeeklyPrimaryContainerComponent} from './channels/channel/schedules/primary/weekly/weekly-primary-containers/weekly-primary-container/weekly-primary-container.component';
import {SchedulingComponent} from './channels/channel/schedules/scheduling/scheduling.component';
import {PrimaryCountersComponent} from './channels/channel/schedules/primary/weekly/primary-counters/primary-counters.component';
import {WeeklyGridComponent} from './channels/channel/schedules/primary/weekly/weekly-grid/weekly-grid.component';
import {WeeklyPrimaryEventComponent} from './channels/channel/schedules/primary/weekly/weekly-primary-containers/weekly-primary-container/weekly-primary-event/weekly-primary-event.component';
import {DailyPrimaryEventComponent} from './channels/channel/schedules/primary/daily/daily-primary-container/daily-primary-event/daily-primary-event.component';
import {MainSideFrameComponent} from './channels/channel/schedules/primary/common/main-side-frame/main-side-frame.component';
import {WeeklySecondaryLayersComponent} from './channels/channel/schedules/secondary/weekly/weekly-secondary-layers/weekly-secondary-layers.component';
import {WeeklySecondaryLayerComponent} from './channels/channel/schedules/secondary/weekly/weekly-secondary-layers/weekly-secondary-layer/weekly-secondary-layer.component';
import {WeeklySecondaryEventComponent} from './channels/channel/schedules/secondary/weekly/weekly-secondary-layers/weekly-secondary-layer/weekly-secondary-event/weekly-secondary-event.component';
import {SecondaryScheduleDetailsComponent} from './channels/channel/schedules/secondary/common/secondary-details/secondary-schedule-details.component';
import {DailySecondaryContainerComponent} from './channels/channel/schedules/secondary/daily/daily-secondary-container/daily-secondary-container.component';
import {DailySecondaryEventComponent} from './channels/channel/schedules/secondary/daily/daily-secondary-container/daily-secondary-event/daily-secondary-event.component';
import {UserSettingsModalComponent} from './page-template/header/user-badge/user-settings-modal/user-settings-modal.component';
import {ChannelRulesErrorsModalComponent} from './channels/channel-rules-errors-modal/channel-rules-errors-modal.component';
import {ChannelPoliciesComponent} from './secondaries/channel-policies/channel-policies.component';
import {RulesEngineModalComponent} from './secondaries/channel-policies/rules-engine-modal/rules-engine-modal.component';
import {SevPolicyContentComponent} from './secondaries/channel-policies/rules-engine-modal/sev-policy-content/sev-policy-content.component';
import {SevRuleContentComponent} from './secondaries/channel-policies/rules-engine-modal/sev-rule-content/sev-rule-content.component';
import {SevPositionContentComponent} from './secondaries/channel-policies/rules-engine-modal/sev-position-content/sev-position-content.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatMenuModule} from '@angular/material/menu';
import {MultipleSchedulingDialogComponent} from './channels/channel/schedules/multiple-scheduling-dialog/multiple-scheduling-dialog.component';
import {MultipleSchedulingDialogContextComponent} from "./channels/channel/schedules/multiple-scheduling-dialog/views/multiple-scheduling-dialog-context/multiple-scheduling-dialog-context.component";
import {MultipleSchedulingDialogOccurrencesComponent} from "./channels/channel/schedules/multiple-scheduling-dialog/views/multiple-scheduling-dialog-occurrences/multiple-scheduling-dialog-occurrences.component";
import {PanelComponent} from "./shared-OLD/components/panel/panel.component";
import {CustomLogoutComponent} from './custom-logout/custom-logout/custom-logout.component';
import {BoxListComponent} from './secondaries/content-boxes/box-list/box-list.component';
import {BoxItemComponent} from './secondaries/content-boxes/box-list/box-item/box-item.component';
import {SubBoxLabelComponent} from './secondaries/content-boxes/box-list/box-item/sub-box-label/sub-box-label.component';
import {BoxModalComponent} from './secondaries/content-boxes/box-modal/box-modal.component';
import {SubBoxItemComponent} from './secondaries/content-boxes/box-modal/sub-box-item/sub-box-item.component';

import {QRCodeModule} from 'angularx-qrcode';
import {EngineComponent} from './channels/channel/channel-egress/engine/engine.component';
import {EgressComponent} from './channels/channel/channel-egress/engine/egress/egress.component';
import {MatIconModule} from "@angular/material/icon";
import {ScheduleTrackComponent} from './secondaries/content-boxes/box-modal/sub-box-panel/schedule-track/schedule-track.component';
import {AdvertisableListComponent} from "./secondaries/content-boxes/box-modal/sub-box-panel/schedule-track/advertisable-list/advertisable-list.component";
import {AdvertisableComponent} from "./secondaries/content-boxes/box-modal/sub-box-panel/schedule-track/advertisable-list/advertisable/advertisable.component";
import {SubBoxPanelComponent} from './secondaries/content-boxes/box-modal/sub-box-panel/sub-box-panel.component';
import {DynamicBoxListComponent} from './channels/channel/schedules/secondary/common/secondary-details/dynamic-box-list/dynamic-box-list.component';
import {DynamicBoxItemComponent} from './channels/channel/schedules/secondary/common/secondary-details/dynamic-box-list/dynamic-box-item/dynamic-box-item.component';
import {DynamicBoxModalComponent} from './channels/channel/schedules/secondary/common/secondary-details/dynamic-box-list/dynamic-box-modal/dynamic-box-modal.component';
import {DynamicInlineSelectComponent} from './dynamic-typed/dynamic-inline-select/dynamic-inline-select.component';
import {StaticStringComponent} from './dynamic-typed/string/static-string/static-string.component';
import {DebugActionListingComponent} from './shared-OLD/utils/debug-action-listing/debug-action-listing.component';
import {GrabberDirective, ResizableDirective} from "./shared-OLD/directives/resizable.directive";
import {SplitComponent} from "./shared-OLD/components/split/split.component";
import {DailyScheduleGroupContainerComponent} from './channels/channel/schedules/schedule-group/components/daily-schedule-group-container/daily-schedule-group-container.component';
import {DailyAdSlotComponent} from './channels/channel/schedules/schedule-group/components/daily-schedule-group-container/daily-ad-slot/daily-ad-slot.component';
import {WeeklyScheduleGroupContainerComponent} from './channels/channel/schedules/schedule-group/components/weekly-schedule-group-container/weekly-schedule-group-container.component';
import {WeeklyAdSlotComponent} from './channels/channel/schedules/schedule-group/components/weekly-schedule-group-container/weekly-ad-slot/weekly-ad-slot.component';
import {AdSlotModalComponent} from './channels/channel/schedules/schedule-group/components/ad-slot-modal/ad-slot-modal.component';
import {MediaManagerComponent} from './media-manager/media-manager.component';
import {MediaControlComponent} from './shared-OLD/components/programs-manager/components/media-control/media-control.component';
import {MediaDetailsComponent} from './media-manager/components/media-details/media-details.component';
import {MediaCreationComponent} from './media-manager/components/media-modals/media-creation/media-creation.component';
import {MediaAuthorizationsComponent} from './media-manager/components/media-modals/media-authorizations/media-authorizations.component';
import {MediaScopeComponent} from './media-manager/components/media-modals/media-scope/media-scope.component';
import {MediaLinkComponent} from './media-manager/components/media-modals/media-link/media-link.component';
import {TreeForScheduleManagerComponent} from "./channels/channel/schedules/tree-for-schedule-manager/tree-for-schedule-manager.component";
import {DeleteAdSlotModalComponent} from './channels/channel/schedules/schedule-group/components/ad-slot-modal/delete-ad-slot-modal/delete-ad-slot-modal.component';
import {DefaultProgramModalComponent} from './channels/channel/channel-details/default-program-modal/default-program-modal.component';
import {SelectAdSlotModalComponent} from './channels/channel/schedules/schedule-group/components/daily-schedule-group-container/daily-ad-slot/select-ad-slot-modal/select-ad-slot-modal.component';
import {ProgramsListingService} from "./programs-listing/services/programs-listing.service";

@NgModule({
    declarations: [
        AppComponent,
        CriteriaLabelComponent,
        MainFooterComponent,
        MainCoreComponent,
        MainHeaderComponent,
        DynamicTypeComponent,
        DynamicPanelComponent,
        StringComponent,
        DurationComponent,
        NumberComponent,
        EnumComponent,
        MainChannelsComponent,
        DateComponent,
        ListComponent,
        ImageComponent,
        MainMenuComponent,
        HomeComponent,
        ChannelDetailsComponent,
        ChannelsConfigComponent,
        StatisticsComponent,
        GlobalSettingsComponent,
        ColorComponent,
        DialogComponent,
        TextComponent,
        DateTimeComponent,
        UserBadgeComponent,
        UserInfoModalComponent,
        GroupsComponent,
        SearchBarComponent,
        ScheduleDetailsComponent,
        OttRightsDetailsComponent,
        BooleanComponent,
        ProgramsListingComponent,
        ProgramItemRowComponent,
        CriteriaComponent,
        TimeRemainingPipe,
        UrgencyPipe,
        GroupSettingsComponent,
        GroupDetailsComponent,
        ChannelsModalComponent,
        CriteriaLabelComponent,
        UniqueCriteriaOptionPipe,
        OttRightsMultipleComponent,
        AddHarmonicIdComponent,
        UserAddComponent,
        DeleteModalComponent,
        ModifyUserPermissionsComponent,
        UserProfilHeaderComponent,
        StatisticComponent,
        GroupsStatisticComponent,
        GroupSynthesisTileComponent,
        GroupTileComponent,
        ChannelsStatisticComponent,
        ChannelSynthesisTileComponent,
        ChannelTileComponent,
        BasicTileComponent,
        ChannelCreationModalComponent,
        ProgramSorterComponent,
        UsersManagementComponent,
        PermissionsComponent,
        UsersListComponent,
        UserItemComponent,
        EditModalComponent,
        MultiSelectComponent,
        AdvancedSearchModalComponent,
        ExportOpcModalComponent,
        DailyPrimaryContainerComponent,
        WeeklyPrimaryContainersComponent,
        WeeklyPrimaryContainerComponent,
        SchedulingComponent,
        PrimaryCountersComponent,
        WeeklyGridComponent,
        WeeklyPrimaryEventComponent,
        DailyPrimaryEventComponent,
        MainSideFrameComponent,
        WeeklySecondaryLayersComponent,
        WeeklySecondaryLayerComponent,
        WeeklySecondaryEventComponent,
        SecondaryScheduleDetailsComponent,
        DailySecondaryContainerComponent,
        DailySecondaryEventComponent,
        SecondariesComponent,
        SecondaryTreeComponent,
        SecondaryTreeNodeComponent,
        GroupNodeComponent,
        ChannelNodeComponent,
        GraphicNodeComponent,
        SecondaryScheduleDetailsComponent,
        SecondaryCreationModalComponent,
        SecondaryDetailsComponent,
        UserSettingsModalComponent,
        ChannelRulesErrorsModalComponent,
        ResizableDirective,
        GrabberDirective,
        ChannelPoliciesComponent,
        RulesEngineModalComponent,
        SevPolicyContentComponent,
        SevRuleContentComponent,
        SevPositionContentComponent,
        CustomLogoutComponent,
        MultipleSchedulingDialogComponent,
        MultipleSchedulingDialogContextComponent,
        MultipleSchedulingDialogOccurrencesComponent,
        PanelComponent,
        SplitComponent,
        CustomLogoutComponent,
        EngineComponent,
        EgressComponent,
        BoxListComponent,
        BoxItemComponent,
        SubBoxLabelComponent,
        BoxModalComponent,
        SubBoxItemComponent,
        ScheduleTrackComponent,
        AdvertisableComponent,
        AdvertisableListComponent,
        SubBoxPanelComponent,
        DynamicBoxListComponent,
        DynamicBoxItemComponent,
        DynamicBoxModalComponent,
        DynamicInlineSelectComponent,
        StaticStringComponent,
        DebugActionListingComponent,
        MediaManagerComponent,
        MediaControlComponent,
        MediaDetailsComponent,
        MediaCreationComponent,
        MediaAuthorizationsComponent,
        MediaScopeComponent,
        MediaLinkComponent,
        TreeForScheduleManagerComponent,
        DailyScheduleGroupContainerComponent,
        DailyAdSlotComponent,
        WeeklyScheduleGroupContainerComponent,
        WeeklyAdSlotComponent,
        AdSlotModalComponent,
        DeleteAdSlotModalComponent,
        DefaultProgramModalComponent,
        SelectAdSlotModalComponent,
        TreeForScheduleManagerComponent
    ],
    imports: [
        OktaAuthModule.initAuth(),
        BrowserModule,
        NgbModule,
        HttpClientModule,
        GraphQLModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES),
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgSelectModule,
        ColorPickerModule,
        DndModule.forRoot(),
        LocationInit,
        LoadingModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        SharedModule.forRoot(),
        UserManagementModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxChartsModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        DragDropModule,
        ReactiveFormsModule,
        QRCodeModule,
        MatIconModule
    ],
    exports: [
        RouterModule
    ],
    providers: [
        ValidateAuthGuard,
        SchedulesService,
        ContextService,
        ChannelsService,
        SevUtilsService,
        GlobalSettingsService,
        UtilsService,
        DynamicViewService,
        SearchCriteriaService,
        ProgramsListingService,
        UrgencyPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedService,
            multi: true
        },
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        CriteriaComponent,
        ChannelsModalComponent,
        UserInfoModalComponent,
        AddHarmonicIdComponent,
        DeleteModalComponent,
        ModifyUserPermissionsComponent,
        ChannelCreationModalComponent,
        EditModalComponent,
        OttRightsMultipleComponent,
        AdvancedSearchModalComponent,
        ExportOpcModalComponent,
        SecondaryScheduleDetailsComponent,
        SecondaryScheduleDetailsComponent,
        SecondaryCreationModalComponent,
        UserSettingsModalComponent,
        ChannelRulesErrorsModalComponent,
        RulesEngineModalComponent,
        BoxModalComponent
    ]
})
export class AppModule {
}
*/
