import {Component, HostListener} from "@angular/core";
import {AboutDialogComponent} from "./core/common/components/about-dialog/about-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {OktaAuthService} from "./libs/okta-angular/src";
import {DebugService} from "./core/common/services/debug.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.altKey && event.ctrlKey && event.shiftKey && event.keyCode === 68) {
            console.log('Alt+Ctrl+Shift+D detected');
            this.debugService.toggleBroadcastDebug2();
        }
    }

    constructor(public dialogService: MatDialog, private oktaAuthService: OktaAuthService, public debugService: DebugService) {

    }

    openAbout() {
        this.dialogService.open(AboutDialogComponent);
    }

    logOut() {
        this.oktaAuthService.logout();
    }
}
